<script setup lang="ts">
    export interface ShopIconProps {
        iconType: 'payment' | 'shipment';
    }
    const props = defineProps<ShopIconProps>();

    const appStore = useAppStore();
    const { website } = storeToRefs(appStore);

    const icons = computed(() => {
        const key = props.iconType === 'payment' ? 'payment_icons' : 'shipment_icons';
        return website.value?.[key] ?? [];
    });
</script>
<template>
    <div class="atm-shop-icons inline-flex flex-wrap gap-2">
        <component
            v-for="icon in icons"
            :key="icon.id"
            :is="icon.data.icon"
            class="h-8 w-12"
            role="img"
            :aria-label="icon.data.icon.substring(8)" />
    </div>
</template>
